import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Table, Button } from 'antd';
import connectScreen from '../redux/connectScreen';
import Container from '../components/Container';
import Title from '../components/Title';
import Label from '../components/Label';

class Shopify extends Component {
    componentDidMount() {
        const { id } = this.props.match.params;
        this.props.getPartnershipProducts(id);
    }

    async syncProduct(productId) {
        const { id } = this.props.match.params;
        await this.props.createPartnershipProduct(id, { productId });
        this.props.getPartnershipProducts(id);
    }
    async deleteProduct(productId) {
        const { id } = this.props.match.params;
        await this.props.deletePartnershipProduct(id, { productId });
        this.props.getPartnershipProducts(id);
    }

    render() {
        const { 
            partnershipProducts, 
            loadingPartnershipProducts,
            creatingPartnershipProduct,
            deletingPartnershipProduct
        } = this.props.partnership;
        return (
            <Container>
                <Title>Products</Title>
                <Label>This is a list of products that are apart of the sync</Label>
                <Table
                    loading={loadingPartnershipProducts}
                    dataSource={partnershipProducts}
                    columns={[
                        {
                            title: 'Title',
                            dataIndex: 'title',
                        },
                        {
                            title: 'Handle',
                            dataIndex: 'handle',
                        },
                        {
                            title: 'Status',
                            dataIndex: 'status',
                        },
                        {
                            title: 'Action',
                            key: 'action',
                            render: (text, record) => (
                                <span>
                                    {
                                        record.status === 'Synced' ?
                                        <p>Synced</p>
                                        : null
                                    }
                                    {
                                        record.status === 'Not Synced' ?
                                        <Button
                                            type='primary'
                                            onClick={() => this.syncProduct(record.id)}
                                            loading={creatingPartnershipProduct}
                                        >
                                            Sync Product
                                        </Button>
                                        : null
                                    }
                                    {
                                        record.status === 'Deleted' ?
                                        <Button
                                            type='danger'
                                            onClick={() => this.deleteProduct(record.id)}
                                            loading={deletingPartnershipProduct}
                                        >
                                            Delete Product
                                        </Button>
                                        : null
                                    }
                                </span>
                            ),
                        },
                    ]}
                />
            </Container>
        );
    }
}

export default connectScreen(withRouter(Shopify));