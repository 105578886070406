import axios from 'axios';
import {
    ORDERS_API,
    API_HEADER
} from '../../../constants/API'
import {
    GET_ORDERS_LOADING,
    GET_ORDERS
} from '../types';
import { message } from 'antd';

export default (type) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: GET_ORDERS_LOADING,
                ordersLoading: true
            })
            const { token } = getState().UserReducer;
            const ordersResponse = await axios.get(`${ORDERS_API}?type=${type}`, API_HEADER(token))
            dispatch({
                type: GET_ORDERS,
                ordersLoading: false,
                orders: ordersResponse.data
            })
        } catch (e) {
            if (e && e.response && e.response.data) {
                console.log(e.response)
                message.error(String(e.response.data))
            }
            dispatch({
                type: GET_ORDERS_LOADING,
                ordersLoading: false
            })

        }
    }
}