import axios from 'axios';
import {
    PARTNERSHIP_API,
    API_HEADER
} from '../../../constants/API'
import {
    UPDATE_PARTNERSHIP_LOADING
} from '../types';
import { message } from 'antd';

export default (id, data) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: UPDATE_PARTNERSHIP_LOADING,
                updatingPartnership: true
            })
            const { token } = getState().UserReducer;
            const partnershipResponse = await axios.put(`${PARTNERSHIP_API}/${id}`, data, API_HEADER(token));
            dispatch({
                type: UPDATE_PARTNERSHIP_LOADING,
                updatingPartnership: false
            })
            return partnershipResponse.data;
        } catch(e) {
            if (e && e.response && e.response.data) {
                message.error(String(e.response.data))
            }            
            dispatch({
                type: UPDATE_PARTNERSHIP_LOADING,
                updatingPartnership: false
            })

        }
    }
}