import axios from 'axios';
import {
    SHOPIFY_API,
    API_HEADER
} from '../../../constants/API'
import {
    GET_SHOPIFY_COLLECTION_LOADING,
    GET_SHOPIFY_COLLECTION
} from '../types';
import { message } from 'antd';

export default () => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: GET_SHOPIFY_COLLECTION_LOADING,
                shopifyCollectionsLoading: true
            })
            const { token, dbUser } = getState().UserReducer;
            const collectionsResponse = await axios.get(`${SHOPIFY_API}/collections?userId=${dbUser.id}`, API_HEADER(token))
            dispatch({
                type: GET_SHOPIFY_COLLECTION,
                shopifyCollectionsLoading: false,
                shopifyCollections: collectionsResponse.data
            })
        } catch(e) {
            if (e && e.response && e.response.data) {
                message.error(String(e.response.data))
            }
            dispatch({
                type: GET_SHOPIFY_COLLECTION_LOADING,
                shopifyCollectionsLoading: false
            })

        }
    }
}