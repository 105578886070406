import styled from 'styled-components';

export default styled.div`
    margin-left: 300px;
    padding: 100px 80px 40px 100px;
    max-height: 100vh;
        overflow-y: scroll;
    @media (max-width: 768px) {
        margin-left: 0px;
        padding: 20px;
        padding-top: 80px;
        
    }
`