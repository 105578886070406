import styled from 'styled-components';

export default styled.textarea`
    width: 100%;
    box-sizing: border-box;
    padding: 15px 15px;
    outline: 0;
    font-size: 16px;
    border: 1px #E1E6ED solid;
    border-radius: 5px;
    transition: all 0.4s ease;
`
