import axios from 'axios';
import {
    CHECKING_SCOPES,
    CHECKING_SCOPES_LOADING
} from '../types';
import {
    SHOPIFY_API,
    API_HEADER,
} from '../../../constants/API'
import { message } from 'antd';

export default (shop, accessToken) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: CHECKING_SCOPES_LOADING,
                checkingScopes: true
            });
            const { token } = getState().UserReducer;
            const postData = {
                shop,
                accessToken
            }
            const scopesResponse = await axios.post(`${SHOPIFY_API}/scopes`, postData, API_HEADER(token));
            
            dispatch({
                type: CHECKING_SCOPES,
                scopeInstructions: scopesResponse.data,
                checkingScopes: false
            });
            return scopesResponse.data;
        } catch(e) {
            dispatch({
                type: CHECKING_SCOPES_LOADING,
                checkingScopes: false
            });
            if (e && e.response && e.response.data) {
                message.error(String(e.response.data))
            }
        }
    }
}