import axios from 'axios';
import {
    PARTNERSHIP_API,
    API_HEADER
} from '../../../constants/API'
import {
    GET_PARTNERSHIPS_LOADING,
    GET_PARTNERSHIPS
} from '../types';
import { message } from 'antd';

export default (status) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: GET_PARTNERSHIPS_LOADING,
                partnershipsLoading: true
            })
            const { token, dbUser } = getState().UserReducer;
            const partnershipsResponse = await axios.get(`${PARTNERSHIP_API}?userId=${dbUser.id}&status=${status}`, API_HEADER(token))
            dispatch({
                type: GET_PARTNERSHIPS,
                partnershipsLoading: false,
                partnerships: partnershipsResponse.data
            })
        } catch (e) {
            if (e && e.response && e.response.data) {
                message.error(String(e.response.data))
            }
            dispatch({
                type: GET_PARTNERSHIPS_LOADING,
                partnershipsLoading: false
            })

        }
    }
}