import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 10px;
    }
`
const Menu = styled.div`
    display: flex;
    @media (max-width: 768px) {
        flex-direction: row;
    }
`
const MenuItem = styled.p`
    cursor: pointer;
    font-size: 32px;
    margin-right: 20px;
    color: ${props => props.selected ? "black" : "#939393"};
    @media (max-width: 768px) {
        font-size: 14px;
        margin-right: 10px;
    }
`

export default ({ menuItems, selected, onSelect, footer }) => {
    return (
        <Container>
            <Menu>
                {menuItems.map(item => (
                    <MenuItem 
                        key={item}
                        selected={item === selected} 
                        onClick={() => onSelect(item)}
                    >
                        {item}
                    </MenuItem>
                ))}
            </Menu>
            
            {footer}
        </Container>
    );
}