import React, { Component } from 'react';
import firebase from 'firebase';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { message, Modal, Alert } from 'antd';
import styled from 'styled-components';
import connectScreen from '../redux/connectScreen';
import Container from '../components/Container';
import Submenu from '../components/Submenu';
import Card from '../components/Card';
import Label from '../components/Label';
import Input from '../components/Input';
import Title from '../components/Title';
import Button from '../components/Button';
import Switch from '../components/Switch';
import LogoPicker from '../components/LogoPicker';
import TextArea from '../components/TextArea';
//import { SHOPIFY_INSTALL_URL } from '../constants/API';

const SettingSection = styled.div`
    margin-top: 20px;
`

class Settings extends Component {
    constructor(props) {
        super(props);
        const { dbUser } = this.props.user;
        this.state = {
            selected: 'General',
            company: dbUser && dbUser.company ? dbUser.company : '',
            isBrand: dbUser && dbUser.isBrand ? dbUser.isBrand : false,
            isSeller: dbUser && dbUser.isSeller ? dbUser.isSeller : false,
            shop: dbUser && dbUser.shop ? dbUser.shop : null,
            code: dbUser && dbUser.code ? dbUser.code : '',
            payoutEmail: dbUser && dbUser.payoutEmail ? dbUser.payoutEmail : '',
            payoutClientId: dbUser && dbUser.payoutClientId ? dbUser.payoutClientId : '',
            payoutSecretId: dbUser && dbUser.payoutSecretId ? dbUser.payoutSecretId : '',
            logo: dbUser && dbUser.logo ? dbUser.logo : null,
            mediaFilesLink: dbUser && dbUser.mediaFilesLink ? dbUser.mediaFilesLink : '',
            supportEmail: dbUser && dbUser.supportEmail ? dbUser.supportEmail : '',
            brandDescription: dbUser && dbUser.brandDescription ? dbUser.brandDescription : '',
            shopifyModalVisible: false,
            shopifyShopUrl: '',
            shopifyAPIPassword: '',
        }
    }
    componentDidMount() {
        const { shouldRefresh } = queryString.parse(this.props.location.search);
        if (shouldRefresh) {
            window.location.replace('/settings')
        }
    }

    async resetPassword() {
        try {
            const { fbUser } = this.props.user;
            const { email } = fbUser;
            await firebase.auth().sendPasswordResetEmail(email);
            message.success('Reset email sent!')
        } catch (e) {
            message.error(e.message)
        }
    }

    async saveUser() {
        const {
            company,
            isBrand,
            isSeller,
            payoutEmail,
            payoutClientId,
            payoutSecretId,
            supportEmail,
            mediaFilesLink,
            logo,
            brandDescription
        } = this.state;
        await this.props.updateUser({
            company,
            isBrand,
            isSeller,
            payoutEmail,
            payoutClientId,
            payoutSecretId,
            supportEmail,
            mediaFilesLink,
            logo,
            brandDescription
        })

    }

    async connectShopify() {
        const { shopifyShopUrl, shopifyAPIPassword } = this.state;
        await this.saveUser();
        const isAccessGranted = await this.checkScopes(shopifyShopUrl, shopifyAPIPassword);
        if (isAccessGranted) {
            window.open(`/shopify?shop=${shopifyShopUrl}&accessToken=${shopifyAPIPassword}`, '_self');
        }
    }

    async checkScopes(shop, accessToken) {
        const scopeInstructions = await this.props.checkScopes(shop, accessToken);
        if (scopeInstructions && scopeInstructions.length <= 0) {
            return true;
        } else {
            return false
        }
    }

    async uploadLogo() {
        //uploads to cloudinary and saves url to state
        const {
            REACT_APP_CLOUDINARY,
            REACT_APP_CLOUDINARY_UPLOAD
        } = process.env;
        window.cloudinary.openUploadWidget({
            cloudName: REACT_APP_CLOUDINARY,
            uploadPreset: REACT_APP_CLOUDINARY_UPLOAD
        }, (error, result) => {
            if (result && result.event === "success") {
                console.log(result)
                //Get Image uploaded
                const { secure_url, original_filename } = result.info;
                //Add contracts to state
                this.setState({
                    logo: secure_url,
                    logoFileName: original_filename
                })
            }
        });
    }


    render() {
        const {
            company,
            isBrand,
            shop,
            code,
            payoutEmail,
            isSeller,
            payoutSecretId,
            payoutClientId,
            shopifyModalVisible,
            shopifyShopUrl,
            shopifyAPIPassword,
            supportEmail,
            mediaFilesLink,
            logo,
            brandDescription
        } = this.state;
        const { scopeInstructions } = this.props.shopify;
        const { updatingUser } = this.props.user;
        const isMobile = window.matchMedia("(max-width: 768px)").matches;
        return (
            <Container>
                <Submenu
                    menuItems={['General', 'Brand', 'Sell']}
                    onSelect={(selected) => this.setState({ selected })}
                    selected={this.state.selected}
                    footer={
                        <Button
                            style={{ width: 100, height: 40, padding: 0 }}
                            loading={updatingUser}
                            onClick={() => this.saveUser()}
                        >
                            Save
                        </Button>
                    }
                />
                <Card>
                    {
                        this.state.selected === 'General' ?
                            <div>
                                <Title style={{ marginBottom: 40 }}>General Settings</Title>
                                <SettingSection>
                                    <Label>Your invite code</Label>
                                    <Title>{code}</Title>
                                </SettingSection>
                                <SettingSection>
                                    <Label>Company</Label>
                                    <Input
                                        placeholder='Company'
                                        type='company'
                                        value={company}
                                        onChange={e => this.setState({ company: e.target.value })}
                                    />
                                </SettingSection>
                                <SettingSection>
                                    <Label>Change Password</Label>
                                    <Button
                                        onClick={() => this.resetPassword()}
                                        style={{ background: '#989898', width: isMobile ? '100%' : '30%' }}
                                    >
                                        Send Password Reset
                                    </Button>
                                </SettingSection>
                                <SettingSection>
                                    <Label>Logout</Label>
                                    <Button
                                        onClick={() => firebase.auth().signOut()}
                                        style={{ background: '#989898', width: isMobile ? '100%' : '30%' }}
                                    >
                                        Logout
                                    </Button>
                                </SettingSection>

                            </div>
                            : null
                    }
                    {
                        this.state.selected === 'Sell' ?
                            <div>
                                <Title style={{ marginBottom: 40 }}>Seller Settings</Title>
                                <SettingSection>
                                    <Label>Do you want to sell other brands products on your store?</Label>
                                    <Switch
                                        checked={isSeller}
                                        onChange={checked => this.setState({ isSeller: checked })}
                                    />
                                    {
                                        isSeller ?
                                            <SettingSection>
                                                <Label>Your Shopify Account</Label>
                                                {
                                                    !shop ?
                                                        <Button
                                                            style={{ width: isMobile ? '100%' : '30%' }}
                                                            onClick={() => this.setState({ shopifyModalVisible: true })}
                                                        >
                                                            Connect Shopify Store
                                                        </Button>
                                                        :
                                                        <SettingSection>
                                                            <Title>
                                                                {shop.url} connected
                                                            </Title>
                                                        </SettingSection>

                                                }
                                                <SettingSection>
                                                    <Label>Paypal API</Label>
                                                    <Input
                                                        placeholder='Paypal Client Id'
                                                        type='payoutClientId'
                                                        value={payoutClientId}
                                                        onChange={e => this.setState({ payoutClientId: e.target.value })}
                                                    />
                                                    <Input
                                                        placeholder='Paypal Secret'
                                                        type='payoutSecretId'
                                                        value={payoutSecretId}
                                                        style={{ marginTop: 10 }}
                                                        onChange={e => this.setState({ payoutSecretId: e.target.value })}
                                                    />
                                                </SettingSection>
                                            </SettingSection>
                                            : null
                                    }

                                </SettingSection>
                            </div>
                            : null
                    }
                    {
                        this.state.selected === 'Brand' ?
                            <div>
                                <Title style={{ marginBottom: 40 }}>Brand Settings</Title>
                                <SettingSection>
                                    <Label>Do you want partner to sell your products on other brands stores?</Label>
                                    <Switch
                                        checked={isBrand}
                                        onChange={checked => this.setState({ isBrand: checked })}
                                    />
                                    {
                                        isBrand ?
                                            <SettingSection>
                                                <Label>Your Shopify Account</Label>
                                                {
                                                    !shop ?
                                                        <Button
                                                            style={{ width: isMobile ? '100%' : '30%' }}
                                                            onClick={() => this.setState({ shopifyModalVisible: true })}

                                                        >
                                                            Connect Shopify Store
                                                        </Button>
                                                        :
                                                        <SettingSection>
                                                            <Title>
                                                                {shop.url} connected
                                                            </Title>

                                                        </SettingSection>

                                                }
                                                <SettingSection>
                                                    <Label>{logo ? 'Logo' : 'Upload Logo'}</Label>
                                                    <LogoPicker
                                                        logo={logo}
                                                        onClick={() => this.uploadLogo()}
                                                    />
                                                </SettingSection>
                                                <SettingSection>
                                                    <Label>Brand Description</Label>
                                                    <TextArea
                                                        placeholder='Brand Description'
                                                        type='brandDescription'
                                                        value={brandDescription}
                                                        onChange={e => this.setState({ brandDescription: e.target.value })}
                                                    />
                                                </SettingSection>
                                                <SettingSection>
                                                    <Label>Paypal Payout Email</Label>
                                                    <Input
                                                        placeholder='Paypal Email'
                                                        type='payoutEmail'
                                                        value={payoutEmail}
                                                        onChange={e => this.setState({ payoutEmail: e.target.value })}
                                                    />
                                                </SettingSection>
                                                <SettingSection>
                                                    <Label>Support Email</Label>
                                                    <Input
                                                        placeholder='Support Email'
                                                        type='supportEmail'
                                                        value={supportEmail}
                                                        onChange={e => this.setState({ supportEmail: e.target.value })}
                                                    />
                                                </SettingSection>
                                                <SettingSection
                                                    data-tip='Enter a link to your media kit or other assets to share with partners'
                                                >
                                                    <Label>Media Files</Label>
                                                    <Input
                                                        placeholder='Media Files'
                                                        type='mediaFilesLink'
                                                        value={mediaFilesLink}
                                                        onChange={e => this.setState({ mediaFilesLink: e.target.value })}
                                                    />
                                                </SettingSection>
                                                <ReactTooltip place="top" type="dark" effect="float" />

                                            </SettingSection>
                                            : null
                                    }

                                </SettingSection>
                            </div>
                            : null
                    }
                </Card>
                <Modal
                    visible={shopifyModalVisible}
                    onCancel={() => this.setState({ shopifyModalVisible: false })}
                    onOk={() => this.connectShopify()}
                    okText='Connect Shopify'
                >
                    <Label>What is your Shopify Url (without https/https) ?</Label>
                    <Input
                        placeholder='Shopify Url'
                        value={shopifyShopUrl}
                        onChange={(e) => this.setState({ shopifyShopUrl: e.target.value })}
                    />
                    <Input
                        placeholder='Shopify API Password'
                        value={shopifyAPIPassword}
                        onChange={(e) => this.setState({ shopifyAPIPassword: e.target.value })}
                    />
                    <a
                        rel="noopener noreferrer"
                        target='_blank'
                        href='https://joinally.freshdesk.com/support/solutions/articles/64000111876-connecting-shopify-as-a-brand-'
                    >
                        Don't have a API Password? click here
                    </a>
                    {
                        scopeInstructions.length > 0 ?
                            <Alert
                                message="Error connecting, you need to enable more permissions:"
                                type="error"
                                description={
                                    scopeInstructions.map((scope, index) => <p>{index + 1}) {scope.scope} access missing. {scope.instruction}</p>)
                                }
                            />
                            : null

                    }

                </Modal>

            </Container>
        )
    }
}

export default withRouter(connectScreen(Settings))