import axios from 'axios';
import {
    REPORTS_API,
    API_HEADER
} from '../../../constants/API'
import {
    GET_PARTNERSHIP_REPORT_LOADING,
    GET_PARTNERSHIP_REPORT
} from '../types';
import { message } from 'antd';

export default (type, startDate, endDate) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: GET_PARTNERSHIP_REPORT_LOADING,
                partnershipReportDataLoading: true
            })
            const { token, dbUser } = getState().UserReducer;
            const reportsResponse = await axios.get(`${REPORTS_API}/${type}/${dbUser.id}/?startDate=${startDate}&endDate=${endDate}`, API_HEADER(token))
            dispatch({
                type: GET_PARTNERSHIP_REPORT,
                partnershipReportDataLoading: false,
                partnershipReportData: reportsResponse.data
            })
        } catch (e) {
            if (e && e.response && e.response.data) {
                message.error(String(e.response.data))
            }
            dispatch({
                type: GET_PARTNERSHIP_REPORT_LOADING,
                partnershipReportDataLoading: false
            })

        }
    }
}