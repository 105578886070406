import React, { Component } from 'react';
import { Table } from 'antd';
import connectScreen from '../redux/connectScreen';
import Container from '../components/Container';
import Submenu from '../components/Submenu';

class Payouts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: 'Received'
        }
    }

    componentDidMount() {
        this.getPayouts()
    }

    getPayouts() {
        const { selected } = this.state;
        this.props.getPayouts(selected)
    }

    setType(selected) {
        this.setState({
            selected
        }, () => this.getPayouts())
    }
    

    getColumns() {
        const isMobile = window.matchMedia("(max-width: 768px)").matches;

        if(isMobile) {
            return [
                {
                    title: 'Payout Date',
                    dataIndex: 'createdAt',
                },
                {
                    title: 'Amount',
                    dataIndex: 'amount',
                },
            ]
        }
        return [
            {
                title: 'Ref #',
                dataIndex: 'payoutReferenceId',
            },
            {
                title: 'Payout Date',
                dataIndex: 'createdAt',
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
            },
            {
                title: 'Payout Email',
                dataIndex: 'payoutEmail',
            },
        ]
    }
    render() {
        const { payouts, loadingPayouts } = this.props.payout;
        return (
            <Container>
                <Submenu
                    menuItems={['Received', 'Sent']}
                    onSelect={(selected) => this.setType(selected)}
                    selected={this.state.selected}
                />
                <Table
                    loading={loadingPayouts}
                    dataSource={payouts}
                    columns={this.getColumns()}
                />

            </Container>
        )
    }
}

export default connectScreen(Payouts)