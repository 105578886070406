import {
    GET_SHOPIFY_COLLECTION_LOADING,
    GET_SHOPIFY_COLLECTION,
    CHECKING_SCOPES,
    CHECKING_SCOPES_LOADING
} from '../actions/types';
const defaultState = {
    shopifyCollections: [],
    shopifyColletionsLoading: false,
    checkingScopes: false,
    scopeInstructions: []
}
export default (state = defaultState, action) => {
    switch (action.type) {
        case GET_SHOPIFY_COLLECTION_LOADING:
            return {
                ...state,
                shopifyColletionsLoading: action.shopifyColletionsLoading
            }
        case GET_SHOPIFY_COLLECTION:
            return {
                ...state,
                shopifyColletionsLoading: action.shopifyColletionsLoading,
                shopifyCollections: action.shopifyCollections
            }
        case CHECKING_SCOPES:
            return {
                ...state,
                checkingScopes: action.checkingScopes,
                scopeInstructions: action.scopeInstructions
            }
        case CHECKING_SCOPES_LOADING:
            return {
                ...state,
                checkingScopes: action.checkingScopes,
            }
        default:
            return state;
    }
}