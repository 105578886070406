import { combineReducers } from 'redux';
import UserReducer from './UserReducer';
import ShopifyReducer from './ShopifyReducer';
import PartnershipReducer from './PartnershipReducer';
import PayoutReducer from './PayoutReducer';
import OrderReducer from './OrderReducer';
import ReportReducer from './ReportReducer';

export default combineReducers({
    UserReducer,
    ShopifyReducer,
    PartnershipReducer,
    PayoutReducer,
    OrderReducer,
    ReportReducer
})