import React, { Component } from 'react';
import firebase from 'firebase';
import { message } from 'antd';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Link, Redirect } from 'react-router-dom';
import connectScreen from '../../redux/connectScreen';
import Title from '../../components/Title';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Label from '../../components/Label';

const AuthContainer = styled(Container)`
    padding: 80px;
    @media (max-width: 768px) {
        padding: 20px;
    }
`
const SignupImage = styled.img`
    width: 100%;
    max-height: calc(100vh - 200px);
    object-fit: contain;
    
    
`
const SignupImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E8E8E8;
    @media (max-width: 768px) {
        display: none;
    }
`

const SignupAuthContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #E8E8E8;
    min-height: 100%;
    padding: 0 20% 0 20%;
    max-height: calc(100vh - 200px);
    @media (max-width: 768px) {
        height: auto;
        padding: 20px 10px 20px 10px;
    }

`
const AuthTitle = styled(Title)`
    margin-bottom: 20px;
    @media (max-width: 768px) {
        margin-bottom: 20px;

    }
`
const AuthInput = styled(Input)`
    margin-bottom: 25px;
`

const InputContainer = styled.div`
    width: 100%;
`
class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            company: '',
            loading: false
        }
    }
    async signup() {
        try {
            const { email, password, company } = this.state
            if (company !== '') {
                await this.setState({ loading: true });
                await localStorage.setItem('company', company);
                await firebase.auth().createUserWithEmailAndPassword(email, password);
            } else {
                message.error('Please enter your company name')
            }
        } catch (e) {
            this.setState({ loading: false })
            message.error(e.message)
        }

    }
    getShopifyValues() {
        const shop = localStorage.getItem('shop');
        const accessToken = localStorage.getItem('accessToken');
        if (
            shop &&
            shop !== '' &&
            shop !== 'undefined' &&
            accessToken &&
            accessToken !== '' &&
            accessToken !== 'undefined'
        ) {
            return { shop, accessToken };
        }
        return false;
    }
    render() {
        const { email, password, loading, company } = this.state;
        const { isLoggedIn } = this.props.user;
        const shopifyValues = this.getShopifyValues()
        if (isLoggedIn) {
            if(shopifyValues) {
                return <Redirect to={`/shopify?shop=${shopifyValues.shop}&accessToken=${shopifyValues.accessToken}`} />
            }
            return <Redirect to='/partners' />
        }
        return (
            <AuthContainer fluid>
                <Row>
                    <Col xs={12} sm={6}>
                        <SignupImageContainer>
                            <SignupImage src={require('../../images/login.jpg')} />
                        </SignupImageContainer>
                    </Col>
                    <Col xs={12} sm={6}>
                        <SignupAuthContainer>
                            <AuthTitle>
                                Sign up
                            </AuthTitle>
                            <InputContainer>
                                <Label>Company</Label>
                                <AuthInput
                                    data-test='company-input'
                                    placeholder='Company'
                                    type='company'
                                    value={company}
                                    onChange={(e) => this.setState({ company: e.target.value })}
                                />
                            </InputContainer>
                            <InputContainer>
                                <Label>Email</Label>
                                <AuthInput
                                    data-test='email-input'
                                    placeholder='Email'
                                    type='email'
                                    value={email}
                                    onChange={(e) => this.setState({ email: e.target.value })}
                                />
                            </InputContainer>
                            <InputContainer>
                                <Label>Password</Label>
                                <AuthInput
                                    data-test='password-input'
                                    placeholder='Password'
                                    type='password'
                                    value={password}
                                    onChange={e => this.setState({ password: e.target.value })}
                                />
                            </InputContainer>
                            <Button
                                data-test='signup-button'
                                loading={loading}
                                onClick={() => this.signup()}
                            >
                                Signup
                            </Button>
                            <Link to='/login'>
                                <Label style={{ marginTop: 20 }}>
                                    Already have an account?
                                </Label>
                            </Link>

                        </SignupAuthContainer>

                    </Col>
                </Row>
            </AuthContainer>
        )
    }
}

export default connectScreen(Signup)