
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { PRIMARY } from '../constants/Colors';

const Logo = styled.img`
    width: auto;
    height: 20px;
`
const Container = styled.div`
    max-width: 100vw;
    display: flex;
    background: black;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 20px;
`
const Nav = styled.div``

const MenuItem = styled(NavLink)`
    color: white;
    margin-left: 10px;
    font-weight: bold;
    text-decoration:none;
`
export default ({ menuItems = [] }) => {
    return (
        <Container
            mode="horizontal"
            defaultSelectedKeys={['1']}
        >
            <Logo src={require('../images/logo.png')} />
            <Nav className="justify-content-end">
                {
                    menuItems.map((item, index) =>
                        <MenuItem
                            key={index + 1}
                            to={item.path}
                            activeStyle={{
                                color: PRIMARY
                            }}
                        >
                            {item.name}

                        </MenuItem>
                    )
                }
            </Nav>
        </Container>
    )
}