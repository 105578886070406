  export default [
    {
        path: '/reports',
        name: 'Reports'
    },
    {
        path: '/partners',
        name: 'Partners'
    },
    {
        path: '/payouts',
        name: 'Payouts'
    },
    {
        path: '/settings',
        name: 'Settings'
    }
]