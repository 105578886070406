import React, { useState } from 'react';
import Sidebar from 'react-sidebar';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import { PRIMARY } from '../constants/Colors';

const Container = styled.div`
    

`
const MenuButton = styled(MenuOutlined)`
    display: none;
    @media (max-width: 768px) {
        display: flex;
        font-size: 32px;
        color: black;
        left: 20px;
        top: 20px;
        position: fixed;
    }
`

const MenuContainer = styled.div`
    padding: 20px;

`
const Logo = styled.img`
    width: 60%; 
    height: auto;
`
const Item = styled(NavLink)`
    color: #e4e4e4;
    font-size: 32px;
    margin-bottom: 40px;
`
const Menu = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 80px;
`
export default ({ menuItems = [] }) => {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    const [open, setSidebarOpen] = useState(false);
    return (
        <Container>
            {isMobile ?
                <MenuButton 
                    onClick={() => setSidebarOpen(true)}
                />
                : null
            }
            <Sidebar
                sidebar={
                    <MenuContainer>
                        <Logo src={require('../images/logo.png')} />
                        <Menu>
                            {menuItems.map(item =>
                                <Item
                                    key={item.path}
                                    to={item.path}
                                    activeStyle={{
                                        color: PRIMARY,
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {item.name}
                                </Item>
                            )}
                        </Menu>
                    </MenuContainer>

                }
                docked={isMobile ? false : true}
                open={open}
                onSetOpen={() => setSidebarOpen(false)}
                styles={{
                    sidebar: {
                        background: "black",
                        width: 300
                    },
                    root: {
                        width: isMobile && !open ? 0 : 300,
                        height: '100vh'
                    }
                }}
            />
        </Container>
    )
}