import React from 'react';
import Login from '../screens/public/Login';
import Signup from '../screens/public/Signup';
import ResetPassword from '../screens/public/ResetPassword';

export default [
    {
        path: '/login',
        screen: (props) => <Login {...props} />,
        exact: true
    },
    {
        path: '/signup',
        screen: (props) => <Signup {...props} />,
        exact: true
    },
    {
        path: '/resetPassword',
        screen: (props) => <ResetPassword {...props} />,
        exact: true
    },
]