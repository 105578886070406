import axios from 'axios';
import {
    SHOPIFY_API,
    API_HEADER
} from '../../../constants/API'
import { message } from 'antd';

export default (shop, accessToken) => {
    return async (dispatch, getState) => {
        try {
            const { token, dbUser } = getState().UserReducer;
            const postData = {
                userId: dbUser.id,
                shop,
                accessToken
            }
            await axios.post(SHOPIFY_API, postData, API_HEADER(token))
        } catch(e) {
            if (e && e.response && e.response.data) {
                message.error(String(e.response.data))
            }
        }
    }
}