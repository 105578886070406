
import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import queryString from 'query-string';
import routes from './routes';
import secretRoutes from './secretRoutes';
import topBarMenu from './topBarMenu';
import sidebarMenu from './sidebarMenu';
import connectScreen from '../redux/connectScreen';
import Topbar from '../components/Topbar';
import Sidebar from '../components/Sidebar';
import LoadingScreen from '../components/LoadingScreen';

class Nav extends Component {
    componentDidMount() {
        this.props.setUserState()
        this.setShopifyValues();
    }
    async setShopifyValues() {
        const {
            shop,
            accessToken
        } = queryString.parse(window.location.search);
        localStorage.setItem('shop', shop);
        localStorage.setItem('accessToken', accessToken);
    }
    render() {
        const { loginLoading, isLoggedIn } = this.props.user;
        if (loginLoading) return <LoadingScreen />
        return (
            <Router>
                    {!isLoggedIn ? <Topbar menuItems={topBarMenu} /> : null}
                    {isLoggedIn ? <Sidebar menuItems={sidebarMenu} /> : null}
                    <Switch>
                        {
                            routes.map(route => {
                                return (
                                    <Route
                                        key={route.path}
                                        path={route.path}
                                        exact={route.exact}
                                    >
                                        {route.screen()}
                                    </Route>
                                )
                            })
                        }
                        {
                            secretRoutes.map(route => {
                                const { isLoggedIn } = this.props.user;
                                if (!isLoggedIn) return <Redirect to='/login' />
                                return (
                                    <Route
                                        key={route.path}
                                        path={route.path}
                                        exact={route.exact}
                                    >
                                        {route.screen()}
                                    </Route>
                                )
                            }

                            )
                        }
                    </Switch>
            </Router>
        )
    }
}

export default connectScreen(Nav);