export const LOGIN_LOADING = 'LOGIN_LOADING';
export const UPDATE_USER_LOADING = 'UPDATE_USER_LOADING';
export const GET_SHOPIFY_COLLECTION = 'GET_SHOPIFY_COLLECTION';
export const GET_SHOPIFY_COLLECTION_LOADING = 'GET_SHOPIFY_COLLECTION_LOADING';
export const GET_PARTNERSHIPS_LOADING = 'GET_PARTNERSHIPS_LOADING';
export const GET_PARTNERSHIPS = 'GET_PARTNERSHIPS';
export const ACCEPT_PARTNERSHIP_LOADING = 'ACCEPT_PARTNERSHIP_LOADING';
export const UPDATE_PARTNERSHIP_LOADING ='UPDATE_PARTNERSHIP_LOADING';
export const CANCEL_PARTNERSHIP_LOADING = 'CANCEL_PARTNERSHIP_LOADING';
export const CREATE_PARTNERSHIP_LOADING = 'CREATE_PARTNERSHIP_LOADING';
export const UPDATE_USER = 'UPDATE_USER';
export const GET_PARTNERSHIP_PRODUCTS_LOADING = 'GET_PARTNERSHIP_PRODUCTS_LOADING'
export const GET_PARTNERSHIP_PRODUCTS = 'GET_PARTNERSHIP_PRODUCTS'
export const CREATE_PARTNERSHIP_PRODUCT_LOADING = 'CREATE_PARTNERSHIP_PRODUCT_LOADING';
export const GET_PAYOUTS_LOADING = 'GET_PAYOUTS_LOADING';
export const GET_PAYOUTS = 'GET_PAYOUTS';
export const GET_ORDERS_LOADING = 'GET_ORDERS_LOADING';
export const GET_ORDERS = 'GET_ORDERS';
export const DELETE_PARTNERSHIP_PRODUCT_LOADING = 'DELETE_PARTNERSHIP_PRODUCT_LOADING';
export const CHECKING_SCOPES_LOADING = 'CHECKING_SCOPES_LOADING';
export const CHECKING_SCOPES = 'CHECKING_SCOPES';
export const GET_PARTNERSHIP_REPORT = 'GET_PARTNERSHIP_REPORT';
export const GET_PARTNERSHIP_REPORT_LOADING = 'GET_PARTNERSHIP_REPORT_LOADING';