import React from 'react';
import styled from 'styled-components';
import uploadImage from '../images/upload.png';


const Container = styled.div`
    cursor: pointer;
`
const Logo = styled.img`
    width: 100px;
    height: 100px;
    object-fit: contain;
`
const UploaderImage = styled.img`
    width: 75px;
    height: 50px;
`
export default (props) => (
    <Container
        onClick={() => props.onClick()} 
    >
        {
            props.logo ?
            <Logo src={props.logo} />
            :
            <UploaderImage src={uploadImage} />
        }
    </Container>
)