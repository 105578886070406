import React, { Component } from 'react';
import {
    Table
} from 'antd';
import connectScreen from '../redux/connectScreen';
import Container from '../components/Container';
import Label from '../components/Label';
import Submenu from '../components/Submenu';

class Orders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: 'Partner'
        }
    }

    componentDidMount() {
        this.getOrders()
    }

    getOrders() {
        const { selected } = this.state;
        this.props.getOrders(selected)
    }

    setType(selected) {
        this.setState({
            selected
        }, () => this.getOrders())
    }

    getColumns() {
        const isMobile = window.matchMedia("(max-width: 768px)").matches;

        if(isMobile) {
            return [
                {
                    title: 'Order #',
                    dataIndex: 'title',
                    render: (text, record) => <p>{this.state.selected === 'Partner' ? record.brandOrderNumber : record.sellerOrderNumber}</p>
                },
                {
                    title: 'Payout Status',
                    dataIndex: 'isPaid',
                    render: (text, record) => <p>{record.isPaid ? 'Paid' : 'Unpaid'}</p>
    
                },
            ]
        }
        return [
            {
                title: 'Order #',
                dataIndex: 'title',
                render: (text, record) => <p>{this.state.selected === 'Partner' ? record.brandOrderNumber : record.sellerOrderNumber}</p>
            },
            {
                title: 'Order Date',
                dataIndex: 'createdAt',
            },
            {
                title: 'Total Price',
                dataIndex: 'totalPrice',
            },
            {
                title: 'Payout Status',
                dataIndex: 'isPaid',
                render: (text, record) => <p>{record.isPaid ? 'Paid' : 'Unpaid'}</p>

            },
        ]
    }

    render() {
        const { orders, loadingOrders } = this.props.order;
        return (
            <Container>
                <Submenu
                    menuItems={['Partner', 'You']}
                    onSelect={(selected) => this.setType(selected)}
                    selected={this.state.selected}
                />
                <Label>
                    {
                        this.state.selected === 'Partner' ?
                            'Orders made on partner sites of your products'
                            : 'Orders made on your sites for partner products'

                    }
                </Label>
                <Table
                    loading={loadingOrders}
                    dataSource={orders}
                    columns={this.getColumns()}
                />

            </Container>
        )
    }
}

export default connectScreen(Orders)