import {
    LOGIN_LOADING,
    UPDATE_USER_LOADING,
    UPDATE_USER
} from '../actions/types';
const defaultState = {
    loginLoading: true,
    isLoggedIn: false,
    fbUser: {},
    dbUser: {
        company: '',
        isBrand: false,
        isSeller: false,
        shop: {
            url: 'menan.myshopify.com'
        },
        code: '21f13',
        payoutEmail: '',
        payoutClientId: '',
        payoutSecretId: ''
    },
    updatingUser: false
}
export default (state = defaultState, action) => {
    switch (action.type) {
        case LOGIN_LOADING:
            return {
                ...state,
                loginLoading: action.loginLoading,
                isLoggedIn: action.isLoggedIn,
                dbUser: action.dbUser,
                fbUser: action.fbUser,
                token: action.token
            }
        case UPDATE_USER_LOADING:
            return {
                ...state,
                updatingUser: action.updatingUser
            }
        case UPDATE_USER:
            return {
                ...state,
                updatingUser: action.updatingUser,
                dbUser: action.dbUser
            }
        default:
            return state;
    }
}