import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import queryString from 'query-string'
import connectScreen from '../redux/connectScreen';
import LoadingSpinner from '../components/LoadingSpinner';
import Container from '../components/Container';
import Title from '../components/Title';
import Label from '../components/Label';


class Shopify extends Component {
    constructor(props) {
        super(props);
        this.state = {
            finishedIntegration: false
        }
    }
    componentDidMount() {
        this.startIntegration();
        this.removeShopifyValues();
    }
    removeShopifyValues() {
        localStorage.removeItem('shop');
        localStorage.removeItem('accessToken');
    }
    async startIntegration() {
        const {
            shop,
            accessToken
        } = queryString.parse(this.props.location.search);
        await this.props.startShopifyIntegration(shop, accessToken);
        this.setState({ 
            finishedIntegration: true
        })

    }
    render() {
        const { finishedIntegration } = this.state;
        if(finishedIntegration) {
            return <Redirect to='/settings?shouldRefresh=true' />
        }
        return (
            <Container>
                <Title>Syncing your Shopify store...</Title>
                <Label>This should take less than 1 min</Label>
                <LoadingSpinner />
            </Container>
        );
    }
}

export default connectScreen(withRouter(Shopify));