import {
    GET_PAYOUTS_LOADING,
    GET_PAYOUTS
} from '../actions/types';
const defaultState = {
    payouts: [],
    payoutsLoading: false
}
export default (state = defaultState, action) => {
    switch (action.type) {
        case GET_PAYOUTS_LOADING:
            return {
                ...state,
                payoutsLoading: action.payoutsLoading
            }
        case GET_PAYOUTS:
            return {
                ...state,
                payoutsLoading: action.payoutsLoading,
                payouts: action.payouts
            }
        default:
            return state;
    }
}