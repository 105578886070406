import axios from 'axios';
import {
    PARTNERSHIP_API,
    API_HEADER
} from '../../../constants/API'
import {
    CREATE_PARTNERSHIP_PRODUCT_LOADING
} from '../types';
import { message } from 'antd';

export default (id, data) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: CREATE_PARTNERSHIP_PRODUCT_LOADING,
                creatingPartnershipProduct: true
            })
            const { token } = getState().UserReducer;
            const postData = { ...data }
            await axios.post(`${PARTNERSHIP_API}/products/${id}`, postData, API_HEADER(token))
            dispatch({
                type: CREATE_PARTNERSHIP_PRODUCT_LOADING,
                creatingPartnershipProduct: false
            })
        } catch(e) {
            if (e && e.response && e.response.data) {
                message.error(String(e.response.data))
            }            
            dispatch({
                type: CREATE_PARTNERSHIP_PRODUCT_LOADING,
                creatingPartnershipProduct: false
            })

        }
    }
}