import {
    GET_ORDERS_LOADING,
    GET_ORDERS
} from '../actions/types';
const defaultState = {
    orders: [],
    ordersLoading: false
}
export default (state = defaultState, action) => {
    switch (action.type) {
        case GET_ORDERS_LOADING:
            return {
                ...state,
                ordersLoading: action.ordersLoading
            }
        case GET_ORDERS:
            return {
                ...state,
                ordersLoading: action.ordersLoading,
                orders: action.orders
            }
        default:
            return state;
    }
}