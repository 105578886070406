import React from 'react';
import styled from 'styled-components';
import LoadingSpinner from './LoadingSpinner';
import Topbar from "./Topbar"
import sidebarMenu from '../nav/sidebarMenu';

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`

export default ({ isLoggedIn = false }) => {
    return (
        <div>
            <Topbar menuItems={isLoggedIn ? sidebarMenu : []} />
            <LoadingContainer>
                <LoadingSpinner />
            </LoadingContainer>
        </div>
    )
}