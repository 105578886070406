import React from 'react';
import Partners from '../screens/Partners';
import Partnership from '../screens/Partnership';
import Settings from '../screens/Settings';
import Shopify from '../screens/Shopify';
import Payouts from '../screens/Payouts';
import Orders from '../screens/Orders';
import Reports from '../screens/Reports';

export default [
    {
        path: '/reports',
        screen: (props) => <Reports {...props} />,
        exact: true
    },
    {
        path: '/partners',
        screen: (props) => <Partners {...props} />,
        exact: true
    },
    {
        path: '/payouts',
        screen: (props) => <Payouts {...props} />,
        exact: true
    },
    {
        path: '/orders',
        screen: (props) => <Orders {...props} />,
        exact: true
    },
    {
        path: '/partnership/:id',
        screen: (props) => <Partnership {...props} />,
        exact: true
    },
    {
        path: '/settings',
        screen: (props) => <Settings {...props} />,
        exact: true
    },
    {
        path: '/shopify',
        screen: (props) => <Shopify {...props} />,
        exact: true
    }
]