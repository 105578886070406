import {
    GET_PARTNERSHIP_REPORT,
    GET_PARTNERSHIP_REPORT_LOADING,
} from '../actions/types';
const defaultState = {
    partnershipReportData: {
        data: [],
        summary: {
            ordersCount: 0,
            gross: 0,
            net: 0,
            commission: 0,
            allyCommission: 0
        }
    },
    partnershipReportDataLoading: false
}
export default (state = defaultState, action) => {
    switch (action.type) {
        case GET_PARTNERSHIP_REPORT_LOADING:
            return {
                ...state,
                partnershipReportDataLoading: action.partnershipReportDataLoading
            }
        case GET_PARTNERSHIP_REPORT:
            return {
                ...state,
                partnershipReportDataLoading: action.partnershipReportDataLoading,
                partnershipReportData: action.partnershipReportData
            }
        default:
            return state;
    }
}