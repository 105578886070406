import firebase from 'firebase';
import axios from 'axios';
import initHelpHero from 'helphero';
import { message } from 'antd';
import {
    USERS_API,
    API_HEADER
} from '../../../constants/API';
import {
    LOGIN_LOADING
} from '../types';

const hlp = initHelpHero('f1AAZygvZGX');


async function getUser(fbUserId, token) {
    /*
        Gets user data from database with fb uid
    */
    try {
        const userResponse = await axios.get(`${USERS_API}/${fbUserId}`, API_HEADER(token));
        return userResponse.data;
    } catch (e) {
        if (!e || !e.response || !e.response.status || e.response.status !== 422) {
            message.error('Your session timeout - please refresh');
            return true;
        }
        return false;
    }
}
async function createUser(fbUserId, token) {
    /*
        Creates user in database with fbuid
    */
    try {
        const company = await localStorage.getItem('company');
        const userResponse = await axios.post(USERS_API, { fbUserId, company }, API_HEADER(token));
        return userResponse.data;
    } catch (e) {
        return false
    }
}


async function getToken() {
    /*
        Get JWT for user
    */
    try {
        return await firebase.auth().currentUser.getIdToken(true);
    } catch (e) {
        //TODO: message error
        return false;
    }
}

function isBrandSetup(user) {
    const isBrand = user.isBrand;
    const shop = isShopSetup(user);
    const payoutEmail = user && user.payoutEmail ? true : false;
    if(isBrand && shop && payoutEmail) {
        return true;
    } 
    return false;
}
function isSellerSetup(user) {
    const isSeller = user.isSeller;
    const payoutClientId = user && user.payoutClientId ? true : false;
    const payoutSecretId = user && user.payoutSecretId ? true : false;
    const shop = isShopSetup(user);
    if(isSeller && shop && payoutClientId && payoutSecretId) {
        return true;
    } 
    return false;
}

function isShopSetup(user) {
    return user && user.shop && user.shop.url ? true : false;
}

function identifyHelpHeroUser(user, fbUser) {
    try {
        const brandSetup = isBrandSetup(user);
        const sellerSetup = isSellerSetup(user);
        const shop = isShopSetup(user);
        hlp.identify(fbUser.uid, {
            sellerSetup,
            brandSetup,
            shop
        });
    } catch (e) {
        console.error(e);
    }
}

function updateHelpHeroUser(user) {
    try {
        const brandSetup = isBrandSetup(user);
        const sellerSetup = isSellerSetup(user);
        const shop = isShopSetup(user);
        hlp.update({
            sellerSetup,
            brandSetup,
            shop
        });
    } catch (e) {
        console.error(e);
    }
}

const setUserState = () => {
    return (dispatch, getState) => {
        dispatch({
            type: LOGIN_LOADING,
            loginLoading: true,
            isLoggedIn: false
        })
        firebase.auth().onAuthStateChanged(async user => {
            if (user) {
                //get db user
                const token = await getToken();
                if (token) {
                    const dbUser = await getUser(user.uid, token);
                    if (dbUser) {
                        dispatch({
                            type: LOGIN_LOADING,
                            loginLoading: false,
                            isLoggedIn: true,
                            fbUser: user,
                            dbUser,
                            token
                        })
                        identifyHelpHeroUser(dbUser, user);
                        return true;
                    } else {
                        //create user
                        const dbUser = await createUser(user.uid, token);
                        dispatch({
                            type: LOGIN_LOADING,
                            loginLoading: false,
                            isLoggedIn: true,
                            fbUser: user,
                            dbUser,
                            token
                        })
                        identifyHelpHeroUser(dbUser, user);
                    }
                }
            } else {
                //no fb user
                dispatch({
                    type: LOGIN_LOADING,
                    loginLoading: false,
                    isLoggedIn: false,
                    fbUser: {},
                    dbUser: {}
                })
            }
        })
    }
}

export { setUserState, getUser, updateHelpHeroUser };