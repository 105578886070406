import React, { Component } from 'react';
import { Modal, Radio, message, Table, Switch, Tooltip } from 'antd';
import styled from 'styled-components';
import connectScreen from '../redux/connectScreen';
import Container from '../components/Container';
import Submenu from '../components/Submenu';
import Button from '../components/Button';
import Card from '../components/Card';
import Label from '../components/Label';
import Input from '../components/Input';
import PartnersTable from '../components/PartnersTable';

const StatusContainer = styled.div`

`
const FormItem = styled.div`
    margin-bottom: 20px;
`

class Partners extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: 'Synced',
            addPartnerModalVisible: false,
            fee: '',
            code: '',
            type: 'seller',
            availableCollections: [],
            shopifyCollectionModalVisible: false,
            selectedRecord: {},
            shouldSyncProduct: false,
            shouldSyncVariant: false,
            shippingLabel: '',
        }
    }
    componentDidMount() {
        this.getPartnerships()
    }
    getPartnerships() {
        const { selected } = this.state;
        this.props.getPartnerships(selected);
    }
    setStatus(selected) {
        this.setState({
            selected
        }, () => this.getPartnerships())
    }
    async acceptPartnership(record) {
        const { availableCollections } = record;
        if (availableCollections && availableCollections.length > 0) {
            await this.props.acceptPartnership(record.id);
            this.setState({ selected: 'Syncing' }, () => this.getPartnerships());
        } else {
            this.setState({
                acceptModalVisible: true,
                selectedRecord: record
            }, () => this.props.getCollections())
        }
    }
    async saveCollectionToRecord(record) {
        const { availableCollections } = this.state;
        if (availableCollections.length > 0) {
            const partnership = await this.props.updatePartnership(record.id, { availableCollections });
            if (partnership) {
                this.setState({
                    acceptModalVisible: false
                },
                    () => this.acceptPartnership(partnership)
                )
            } else {
                message.error('Error updating partnership')
            }
        } else {
            message.error('Select atleast 1 collection')
        }


    }
    async declinePartnership(record) {
        await this.props.updatePartnership(record.id, { status: 'Cancelled' });
        await this.getPartnerships();
    }
    cancelPartnership(record) {
        message.warn('Cancelling in background - check cancellated tab.')
        this.props.cancelPartnership(record.id)
    }

    async addPartner() {
        const {
            code,
            fee,
            type,
            availableCollections,
            shouldSyncProduct,
            //         shouldSyncVariant,
            shippingLabel
        } = this.state;
        if (code === '') {
            message.error('You must enter a code')
            return
        }
        if (isNaN(fee) || fee === '') {
            message.error('Fee must be a number')
            return
        }
        if (type !== 'brand' && type !== 'seller') {
            message.error('You must select brand or seller');
            return
        }
        if (type === 'brand' && availableCollections.length <= 0) {
            message.error('You must select a collection to sync');
            return
        }
        const createdPartnership = await this.props.createPartnership({
            code,
            fee,
            type,
            availableCollections,
            shouldSyncProduct,
            shouldSyncVariant: true, //DISABLE
            shippingLabel
        });
        if (createdPartnership) {
            this.setState({ addPartnerModalVisible: false });
            this.setStatus('Invited')
        }
    }

    openCollectionModal() {
        this.setState({
            shopifyCollectionModalVisible: true
        },
            () => this.props.getCollections()
        )
    }
    openCustomAcceptModal() {
        this.setState({
            acceptModalVisible: true
        },
            () => this.props.getCollections()
        )
    }

    render() {
        const { partnerships, partnershipsLoading, creatingPartnership } = this.props.partnership;
        const { dbUser } = this.props.user;
        const { shopifyCollections } = this.props.shopify;
        const {
            addPartnerModalVisible,
            fee,
            code,
            type,
            shopifyCollectionModalVisible,
            availableCollections,
            acceptModalVisible,
            selectedRecord,
            shouldSyncProduct,
            //      shouldSyncVariant,
            shippingLabel,
        } = this.state;
        return (
            <Container>
                <Submenu
                    menuItems={['Synced', 'Syncing', 'Invited', 'Pending', 'Cancelled']}
                    onSelect={(selected) => this.setStatus(selected)}
                    selected={this.state.selected}
                    footer={
                        <Button
                            style={{ width: 150, height: 40, padding: 0 }}
                            onClick={() => this.setState({ addPartnerModalVisible: true })}
                        >
                            Add Partner
                        </Button>
                    }
                />
                <Card>
                    {this.state.selected === 'Syncing' ?
                        <StatusContainer>
                            <Label>Partners being synced</Label>
                            <PartnersTable
                                data={partnerships}
                                loading={partnershipsLoading}
                                userId={dbUser.id}
                                showSyncing
                            />
                        </StatusContainer>
                        : null
                    }
                    {this.state.selected === 'Invited' ?
                        <StatusContainer>
                            <Label>Invites you have sent to other partners</Label>
                            <PartnersTable
                                data={partnerships}
                                loading={partnershipsLoading}
                                userId={dbUser.id}
                            />
                        </StatusContainer>
                        : null
                    }
                    {this.state.selected === 'Pending' ?
                        <StatusContainer>
                            <Label>Invites you have received from other partners</Label>
                            <PartnersTable
                                data={partnerships}
                                loading={partnershipsLoading}
                                acceptTitle={'Accept'}
                                acceptDesc={(record) => `The fee set for this partnership is ${record.fee}%. Would you like accept?`}
                                onAcceptClick={(record) => this.acceptPartnership(record)}
                                onDeclineClick={(record) => this.declinePartnership(record)}
                                declineTitle={'Decline'}
                                userId={dbUser.id}
                                declineDesc={() => 'Decline is permanent. You or the partner will need to resend an invite to redo this.'}
                            />
                        </StatusContainer>
                        : null
                    }
                    {this.state.selected === 'Synced' ?
                        <StatusContainer>
                            <Label>Partners you have synced</Label>
                            <PartnersTable
                                data={partnerships}
                                loading={partnershipsLoading}
                                onDeclineClick={(record) => this.cancelPartnership(record)}
                                declineTitle={'Cancel Sync'}
                                declineDesc={() => 'Cancelling will remove the connection between you and the partners store. This will delete any products shared, and remove future sync.'}
                                userId={dbUser.id}
                                partnerLink={(record) => `partnership/${record.id}`}
                                partnerLinkText={(record) => `Manage Sync`}

                            />
                        </StatusContainer>
                        : null
                    }
                    {this.state.selected === 'Cancelled' ?
                        <StatusContainer>
                            <Label>Partners you have cancelled</Label>
                            <PartnersTable
                                data={partnerships}
                                loading={partnershipsLoading}
                                userId={dbUser.id}

                            />
                        </StatusContainer>
                        : null
                    }
                </Card>
                <Modal
                    title='Add a partner'
                    visible={addPartnerModalVisible}
                    onCancel={() => this.setState({ addPartnerModalVisible: false })}
                    onOk={() => this.addPartner()}
                    okText='Invite Partner'
                    loading={creatingPartnership}
                >
                    <FormItem>
                        <Label>Are you the brand or seller?</Label>
                        <Radio.Group
                            onChange={(e) => this.setState({ type: e.target.value })}
                            value={type}
                        >
                            <Radio value={'brand'}>Brand</Radio>
                            <Radio value={'seller'}>Seller</Radio>
                        </Radio.Group>
                    </FormItem>
                    {
                        type === 'brand' ?
                            <div>
                                <FormItem>
                                    <Label>Set available products</Label>
                                    <Button
                                        onClick={() => this.openCollectionModal()}
                                    >
                                        Select Product Collection
                            </Button>
                                </FormItem>
                                <Tooltip
                                    title="Only set if the shipping method used should not be auto set according to your Shopify Shipping Zones. Note, this value should be the name of the shipping method you would like to override"
                                >
                                    <FormItem>
                                        <Label>Override Shipping Method Used (Optional)</Label>
                                        <Input
                                            placeholder='Override Shipping Method'
                                            value={shippingLabel}
                                            onChange={(e) => this.setState({ shippingLabel: e.target.value })}
                                        />
                                    </FormItem>
                                </Tooltip>
                            </div>
                            : null
                    }
                    <FormItem>
                        <Label>What is the partners invite code (found in partners Settings Tab)?</Label>
                        <Input
                            placeholder='code'
                            value={code}
                            onChange={(e) => this.setState({ code: e.target.value })}
                        />
                    </FormItem>
                    <FormItem>
                        <Label>How much % will be received per sale?</Label>
                        <Input
                            placeholder='Fee'
                            value={fee}
                            onChange={(e) => this.setState({ fee: e.target.value })}
                        />
                    </FormItem>


                    <FormItem>
                        <Label>Should we automatically update product changes from the brand to the sellers shop (title, body, images)?</Label>
                        <Switch
                            checked={shouldSyncProduct}
                            onChange={(checked) => this.setState({ shouldSyncProduct: checked })}
                        />
                    </FormItem>
                    {/* DISABLED<FormItem>
                    <Label>Should we automatically update variant changes from the brand to the sellers shop (price, cost)?</Label>
                        <Switch
                            checked={shouldSyncVariant}
                            onChange={(checked) => this.setState({ shouldSyncVariant: checked })}
                        />
                   </FormItem> */}
                    <Modal
                        visible={shopifyCollectionModalVisible}
                        onCancel={() => this.setState({ shopifyCollectionModalVisible: false })}
                        onOk={() => this.setState({ shopifyCollectionModalVisible: false })}
                    >
                        <Label>
                            Select the collection you'd like to make available
                    </Label>
                        <Table
                            rowKey='id'
                            rowSelection={{
                                onChange: (selectedRowKeys, selectedRows) => this.setState({ availableCollections: selectedRows }),
                                selectedRowKeys: availableCollections.map(obj => obj.id)
                            }}
                            columns={[
                                {
                                    title: 'Title',
                                    dataIndex: 'title'
                                },
                                {
                                    title: 'Handle',
                                    dataIndex: 'handle',
                                },
                            ]}
                            dataSource={shopifyCollections}
                        />

                    </Modal>
                </Modal>
                <Modal
                    title='Select which collections to sync'
                    visible={acceptModalVisible}
                    onCancel={() => this.setState({ acceptModalVisible: false })}
                    onOk={() => this.saveCollectionToRecord(selectedRecord)}
                    okText={'Select & Accept'}
                >
                    <Table
                        rowKey='id'
                        rowSelection={{
                            onChange: (selectedRowKeys, selectedRows) => this.setState({ availableCollections: selectedRows }),
                            selectedRowKeys: availableCollections.map(obj => obj.id)
                        }}
                        columns={[
                            {
                                title: 'Title',
                                dataIndex: 'title'
                            },
                            {
                                title: 'Handle',
                                dataIndex: 'handle',
                            },
                        ]}
                        dataSource={shopifyCollections}
                    />
                </Modal>

            </Container>
        )
    }
}

export default connectScreen(Partners)