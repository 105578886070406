import {
    bindActionCreators
} from 'redux';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';

function mapStateToProps(state, props) {
    return {
        user: state.UserReducer,
        shopify: state.ShopifyReducer,
        partnership: state.PartnershipReducer,
        payout: state.PayoutReducer,
        order: state.OrderReducer,
        report: state.ReportReducer
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(actions, dispatch);
}

export default (screen) => connect(mapStateToProps, mapDispatchToProps)(screen);