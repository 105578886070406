import axios from 'axios';
import {
    PARTNERSHIP_API,
    API_HEADER
} from '../../../constants/API'
import {
    ACCEPT_PARTNERSHIP_LOADING
} from '../types';
import { message } from 'antd';

export default (id) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: ACCEPT_PARTNERSHIP_LOADING,
                acceptingPartnership: true
            })
            const { token } = getState().UserReducer;
            await axios.get(`${PARTNERSHIP_API}/accept/${id}`, API_HEADER(token));
            dispatch({
                type: ACCEPT_PARTNERSHIP_LOADING,
                acceptingPartnership: false
            })
        } catch (e) {
            if (e && e.response && e.response.data) {
                message.error(String(e.response.data))
            }
            dispatch({
                type: ACCEPT_PARTNERSHIP_LOADING,
                acceptingPartnership: false
            })

        }
    }
}