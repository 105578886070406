import React, { Component } from 'react';
import firebase from 'firebase';
import { message } from 'antd';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Link, Redirect } from 'react-router-dom';
import connectScreen from '../../redux/connectScreen';
import Title from '../../components/Title';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Label from '../../components/Label';
const AuthContainer = styled(Container)`
    padding: 80px;
    @media (max-width: 768px) {
        padding: 20px;
    }
`
const LoginImage = styled.img`
    width: 100%;
    max-height: calc(100vh - 200px);
    object-fit: contain;
`
const LoginImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E8E8E8;
    @media (max-width: 768px) {
        display: none;
    }
`

const LoginAuthContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #E8E8E8;
    min-height: 100%;
    padding: 20%;
    max-height: calc(100vh - 160px);
    @media (max-width: 768px) {
        height: auto;
        padding: 20px 10px 20px 10px;
    }

`
const AuthTitle = styled(Title)`
    margin-bottom: 20px;
`
const AuthInput = styled(Input)`
    margin-bottom: 25px;
`

const InputContainer = styled.div`
    width: 100%;
`
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            loading: false
        }
    }
    async login() {
        try {
            const { email, password } = this.state
            await this.setState({ loading: true });
            await firebase.auth().signInWithEmailAndPassword(email, password);
        } catch (e) {
            this.setState({ loading: false })
            message.error(e.message)
        }

    }
    getShopifyValues() {
        const shop = localStorage.getItem('shop');
        const accessToken = localStorage.getItem('accessToken');
        if (
            shop &&
            shop !== '' &&
            shop !== 'undefined' &&
            accessToken &&
            accessToken !== '' &&
            accessToken !== 'undefined'
        ) {
            return { shop, accessToken };
        }
        return false;
    }
    render() {
        const { email, password, loading } = this.state;
        const { isLoggedIn } = this.props.user;
        const shopifyValues = this.getShopifyValues();
        if (isLoggedIn) {
            if (shopifyValues) {
                return <Redirect to={`/shopify?shop=${shopifyValues.shop}&accessToken=${shopifyValues.accessToken}`} />
            }
            return <Redirect to='/partners' />
        }
        return (
            <AuthContainer fluid>
                <Row>
                    <Col xs={12} sm={6}>
                        <LoginImageContainer>
                            <LoginImage src={require('../../images/signup.jpg')} />
                        </LoginImageContainer>
                    </Col>
                    <Col xs={12} sm={6}>
                        <LoginAuthContainer>
                            <AuthTitle>
                                Log In
                            </AuthTitle>
                            <InputContainer>
                                <Label>Email</Label>
                                <AuthInput
                                    placeholder='Email'
                                    type='email'
                                    value={email}
                                    onChange={(e) => this.setState({ email: e.target.value })}
                                />
                            </InputContainer>
                            <InputContainer>
                                <Label>Password</Label>
                                <AuthInput
                                    placeholder='Password'
                                    type='password'
                                    value={password}
                                    onChange={e => this.setState({ password: e.target.value })}
                                />
                            </InputContainer>
                            <Button
                                loading={loading}
                                onClick={() => this.login()}
                            >
                                Login
                            </Button>
                            <Link to='/resetPassword'>
                                <Label style={{ marginTop: 20 }}>
                                    Forgot Password?
                                </Label>
                            </Link>

                        </LoginAuthContainer>

                    </Col>
                </Row>
            </AuthContainer>
        )
    }
}

export default connectScreen(Login)