import axios from 'axios';
import {
    PAYOUT_API,
    API_HEADER
} from '../../../constants/API'
import {
    GET_PAYOUTS_LOADING,
    GET_PAYOUTS
} from '../types';
import { message } from 'antd';

export default (type) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: GET_PAYOUTS_LOADING,
                payoutsLoading: true
            })
            const { token } = getState().UserReducer;
            const payoutsResponse = await axios.get(`${PAYOUT_API}?type=${type}`, API_HEADER(token))
            dispatch({
                type: GET_PAYOUTS,
                payoutsLoading: false,
                payouts: payoutsResponse.data
            })
        } catch (e) {
            if (e && e.response && e.response.data) {
                message.error(String(e.response.data))
            }
            dispatch({
                type: GET_PAYOUTS_LOADING,
                payoutsLoading: false
            })

        }
    }
}